/* eslint-disable indent */

import TopNav from '../layout/TopNav';
import { createContext, Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import Pagination from '../modules/Pagination';
import { Caption, Subtitle } from '../modules/Typography';
import axios from 'axios';
import PaginationRange from '../modules/PaginationRange';

export const RequestContext = createContext();

console.log('cookie=' + document.cookie);
const RequestDashboard = () => {
    const timestamp = new Date().toLocaleDateString();
    const [body, setBody] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState('modifiedDate');
    const [sortDir, setSortDir] = useState('desc');
    const [pageSize, setPageSize] = useState(50);
    const [pageNum, setPageNum] = useState(1);
    const [total, setTotal] = useState(0);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState(new Array(pageSize).fill(false));

    useEffect(() => {
        getRequests();
    }, [loading]);

    const handleChangeAll = () => {
        setCheckedAll(!checkedAll);
        const temp = [];
        for (let i = 0; i < body.length; i++) {
            temp[i] = !checkedAll;
        }
        setChecked(temp);
    };

    const handleChange = (index) => {
        const temp = [...checked];
        temp[index] = !temp[index];
        setChecked(temp);
    };

    const updatePagination = (data) => {
        setPageSize(data.limit);
        setPageNum(data.page);
        setLoading(true);
    };
    console.log('cookies=' + document.cookie);
    const changeSort = (newSort) => {
        if (newSort !== sort) {
            setSort(newSort);
            setSortDir('desc');
        } else {
            if (sortDir === 'asc') {
                setSortDir('desc');
            } else {
                setSortDir('asc');
            }
        }
        setLoading(true);
        getRequests();
    };

    const SortArrow = (data) => {
        if (data.sort === data.columnName && data.sortDir === 'asc') {
            return <span className='sort-direction' style={{ position: 'absolute' }}>&uarr;</span>;
        } else if (data.sort === data.columnName && data.sortDir === 'desc') {
            return <span className='sort-direction' style={{ position: 'absolute' }}>&darr;</span>;
        }
    };

    const formatDate = (date) => {
        const dateObj = new Date(date);

        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = String(dateObj.getFullYear()).slice(-2); // Get last two digits of the year

        return `${month}/${day}/${year}`;
    };

    const getRequests = () => {
        const url = `/api/requests?pageNum=${pageNum}&pageSize=${pageSize}&sort=${sort}&sortDir=${sortDir}`;

        if (loading) {
            axios.get(url, {
                method: 'get',
                withCredentials: true,
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                })
            }).then((response) => {
                const data = response.data;

                if (data) {
                    if (data.total) {
                        setTotal(data.total);
                    }
                    if (data.requests) {
                        setBody(data.requests);
                    }
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                console.log('Couldnt get requests ' + error);
            });
        }
    };

    return (
        <>
            <Helmet><title>Requests</title></Helmet>
            <TopNav
                heading='Requests'
                btnText='Create Request'
                onClick={() => getRequests()}
            />

            <div className='content-wrapper'>
                <div className='DashboardHeader DashboardHeader__title'>
                    <div className='DashboardHeader__Refresh flex flex-end half-spacing__bottom'>
                        <Button
                            icon='refresh-ccw' type='surface' size='small' onClick={() => alert('test')}
                            className='DashboardHeader__Refresh--static' />
                        <Caption className='spacing__left'>Refreshed {timestamp}</Caption>
                    </div>
                    <div className='flex stretch-width'>
                        <div className='DashboardHeader__Title flex'>
                            <Subtitle number={1} className='flex flex-start' color='primary'>All Requests</Subtitle>

                        </div>

                        <div className='DashboardHeader__Controls flex'>
                            <TextField
                                icon='Search'
                                placeholder='Search Request...'
                                padded={false}
                                onChange={() => alert('handle search')}
                                value={() => alert('search Query')}
                                onSubmit={() => alert('handleSearchSubmit')}
                                className='DashboardHeader__Search'
                                clear={() => alert('clear')}
                                onClear={() => alert('onCloent')}
                            />

                            <Button
                                icon='filter' onClick={() => alert('filter')}
                                className='spacing__left filters-applied' />
                        </div>
                    </div>
                </div>

                <div className='flex stretch-width'>
                    <div className='Pagination'>
                        <PaginationRange paginationData={{ limit: pageSize, page: pageNum, totalCount: total }} type='header' />
                    </div>
                </div>

                <table style={{ minWidth: '1525px', width: '100%', borderCollapse: 'collapse' }}>
                    <thead className='class="ReactTable__Header sticky'>
                        <tr style={{ backgroundColor: '#eff4fe', fontSize: '14px' }}>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '75px', maxWidth: '75px' }}><input type='checkbox' checked={checkedAll} onChange={() => handleChangeAll()} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '75px', maxWidth: '75px' }} onClick={() => changeSort('status')}>Status<SortArrow columnName='status' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '300px', maxWidth: '400px' }} onClick={() => changeSort('name')}>Request Name<SortArrow columnName='name' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '200px', maxWidth: '200px' }} onClick={() => changeSort('type')}>Type<SortArrow columnName='type' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '50px', maxWidth: '50pxpx' }} onClick={() => changeSort('audience')}>Audience<SortArrow columnName='audience' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '150px', maxWidth: '150px' }} onClick={() => changeSort('requestedBy')}>Requested By<SortArrow columnName='requestedBy' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '150px', maxWidth: '150px' }} onClick={() => changeSort('requestedTeam')}>Requested Team<SortArrow columnName='requestedTeam' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '150px', maxWidth: '150px' }} onClick={() => changeSort('createdDate')}>Requested Date<SortArrow columnName='createdDate' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '150px', maxWidth: '150px' }} onClick={() => changeSort('dueDate')}>Due Date<SortArrow columnName='dueDate' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '150px', maxWidth: '150px' }} onClick={() => changeSort('startAt')}>Start Date<SortArrow columnName='startAt' sort={sort} sortDir={sortDir} /></th>
                            <th className='RequestTable__requestHeader RequestTable__link' style={{ minwidth: '150px', maxWidth: '150px' }} onClick={() => changeSort('endAt')}>End Date<SortArrow columnName='endAt' sort={sort} sortDir={sortDir} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {body.map((item, index) => (
                            <tr key={`tr-${index}`} style={{ fontSize: '14px', borderBottom: '1pt solid #EDEDED' }}>
                                <td className='RequestTable__requestbody'><input type='checkbox' value={index} checked={checked[index]} onChange={() => handleChange(index)} /></td>
                                <td className='RequestTable__requestBody'><div className='Status Status--default Status--created'>{item.status}</div></td>
                                <td className='RequestTable__requestBody'>{item.name}</td>
                                <td className='RequestTable__requestBody'>{item.type}</td>
                                <td className='RequestTable__requestBody'>100</td>
                                <td className='RequestTable__requestBody'>{item.requestedBy}</td>
                                <td className='RequestTable__requestBody'>{item.requestedTeam}</td>
                                <td className='RequestTable__requestBody'>{formatDate(item.createdDate)}</td>
                                <td className='RequestTable__requestBody'>{formatDate(item.dueDate)}</td>
                                <td className='RequestTable__requestBody'>{formatDate(item.event.startAt)}</td>
                                <td className='RequestTable__requestBody'>{formatDate(item.event.endAt)}</td>
                            </tr>
                    ))}
                    </tbody>
                </table>

                <Pagination paginationData={{ totalCount: total, limit: pageSize, page: pageNum, updatePagination }} />
            </div>
        </>
    );
};

export default RequestDashboard;
