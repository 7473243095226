// See below for config structure details
export const fileProcessingConfig = {
    disabledFileTypes: ['atmos'],
    video: {
        details: [
            {
                id: 'resolution'
            },
            {
                id: 'ColorSpace'
            },
            {
                id: 'framerate',
                format: 'framerate'
            },
            {
                id: 'Duration',
                format: 'time'
            }
        ],
        fields: [
            {
                id: 'jobType',
                label: 'Job',
                type: 'select',
                appConfigData: 'genieJobTypes',
                relatedValues: {
                    shortform: [
                        {
                            configId: 'watermarkingMode',
                            value: 'clipmark'
                        }
                    ],
                    standard: [
                        {
                            configId: 'watermarkingMode',
                            value: 'ngs'
                        }
                    ],
                    proxisUnprotected: [
                        {
                            configId: 'watermarkingMode',
                            value: 'none'
                        },
                        {
                            configId: 'fileFormat',
                            changeValue: 'sd-hd_sdr',
                            value: 'uhd_sdr'
                        }
                    ],
                    proxisShortform: [
                        {
                            configId: 'watermarkingMode',
                            value: 'clipmark'
                        },
                        {
                            configId: 'fileFormat',
                            changeValue: 'sd-hd_sdr',
                            value: 'uhd_sdr'
                        }
                    ],
                    unprotected: [
                        {
                            configId: 'watermarkingMode',
                            value: 'none'
                        }
                    ]
                },
                filterSelectOptions: [
                    {
                        configId: 'playbackSource',
                        values: ['VPP'],
                        optionsToRemove: ['proxisShortform', 'proxisUnprotected']
                    }
                ]
            },
            {
                id: 'fileFormat',
                label: 'Format',
                type: 'select',
                appConfigData: 'videoFormats',
                validation: { type: 'dolby' },
                errorMessage: 'DolbyVisionXml Not Provided',
                conditionalFields: {
                    uhd_dovi: [{
                        id: 'dolbyVisionXml',
                        type: 'dolby'
                    }],
                    uhd_hdr10: [{
                        id: 'hdr',
                        type: 'select',
                        appConfigData: 'hdrMetadatas',
                        defaultVal: 'p3-d65',
                        conditionalFields: {
                            custom: [{
                                id: 'hdrMetadata',
                                type: 'text',
                                validation: { type: 'hdrMetadata' },
                                errorMessage: 'Invalid metadata. Valid example: G(8500,39850)B(6550,2300)R(35400,14600) WP(15635,16450)L(40000000,50)'
                            }]
                        }
                    }]
                },
                filterSelectOptions: [
                    {
                        configId: 'jobType',
                        values: ['proxisUnprotected', 'proxisShortform'],
                        optionsToRemove: ['sd-hd_sdr']
                    },
                    {
                        configId: 'playbackSource',
                        values: ['VPP'],
                        optionsToRemove: ['uhd_sdr', 'uhd_hdr10', 'uhd_dovi']
                    }
                ]
            },
            {
                id: 'language',
                label: 'Language',
                type: 'select',
                appConfigData: 'rfc5646LanguageTags',
                defaultVal: 'en'
            },
            {
                id: 'playbackSource',
                type: 'select',
                appConfigData: 'processingPlaybackSource',
                label: 'Playback Source',
                defaultVal: 'debut',
                featureFlag: 'VPPIntegration',
                disabled: true
            }
        ],
        processingFields: [
            {
                type: 'reprocess'
            },
            {
                id: 'multiRegion',
                label: 'International Review',
                type: 'switch',
                defaultVal: true
            }
        ],
        advancedFields: [
            {
                id: 'uploadTimeStamp',
                label: 'CDN Timestamp',
                type: 'textWithButton',
                buttonFunction: () => Date.now(),
                buttonCopy: 'New Timestamp',
                validation: {
                    type: 'number',
                    digits: 13
                },
                formatting: 'number',
                errorMessage: 'Upload Timestamp must be a number'

            },
            {
                id: 'chunkSize',
                label: 'Chunk Size',
                type: 'text',
                validation: { type: 'number' },
                formatting: 'number',
                errorMessage: 'Invalid number'
            },
            {
                id: 'audioKind',
                label: 'Audio Type',
                type: 'select',
                appConfigData: 'audioKinds'
            },
            {
                id: 'watermarkingMode',
                label: 'Watermarking Mode',
                type: 'text',
                disabled: true
            },
            {
                id: 'clipmarkStart',
                label: 'Clipmark Payload Start',
                type: 'text',
                validation: { type: 'number' },
                formatting: 'number',
                errorMessage: 'Invalid number',
                renderField: [
                    {
                        configId: 'jobType',
                        values: ['shortform', 'proxisShortform']
                    }
                ]
            },
            {
                id: 'clipmarkEnd',
                label: 'Clipmark Payload End',
                type: 'text',
                validation: [
                    { type: 'checkClipmarkEnd' },
                    {
                        type: 'checkClipmarkThreshold',
                        configId: 'clipmarkThreshold'
                    }
                ],
                formatting: 'number',
                errorMessage: 'Clipmark End should be a number greater or equal to Threshold. (Max: 5000)',
                renderField: [
                    {
                        configId: 'jobType',
                        values: ['shortform', 'proxisShortform']
                    }
                ]
            },
            {
                id: 'clipmarkThreshold',
                label: 'Clipmark Threshold',
                type: 'text',
                validation: [
                    { type: 'checkClipmarkThreshold' },
                    {
                        type: 'checkClipmarkEnd',
                        configId: 'clipmarkEnd'
                    }
                ],
                formatting: 'number',
                errorMessage: 'Threshold should be a number lower or equal to Clipmark End. (Max: 1000)',
                renderField: [
                    {
                        configId: 'jobType',
                        values: ['shortform', 'proxisShortform']
                    }
                ]
            },
            {
                id: 'encoder',
                label: 'Encoding Engine',
                type: 'select',
                appConfigData: 'encoder',
                renderField: [
                    {
                        configId: 'jobType',
                        values: ['proxisUnprotected', 'proxisShortform']
                    }
                ]
            },
            {
                id: 'dPlusLadderDebug',
                label: 'D+ Ladder Debug',
                type: 'switch',
                defaultVal: false,
                renderField: [
                    {
                        configId: 'jobType',
                        values: ['proxisUnprotected', 'proxisShortform']
                    }
                ]
            }
        ],
        requiredFields: ['jobType', 'fileFormat', 'language']
    },
    videoDubCard: {
        details: [
            {
                id: 'resolution'
            },
            {
                id: 'ColorSpace'
            },
            {
                id: 'framerate',
                format: 'framerate'
            },
            {
                id: 'Duration',
                format: 'time'
            }
        ],
        fields: [
            {
                id: 'dubbingCredit',
                label: 'Format',
                type: 'select',
                appConfigData: 'dubbingCredit',
                defaultVal: 'dubbingCredit',
                featureFlag: 'VPPIntegration',
                disabled: true
            },
            {
                id: 'language',
                label: 'Language',
                type: 'select',
                appConfigData: 'rfc5646LanguageTags',
                defaultVal: 'en'
            },
            {
                id: 'playbackSource',
                type: 'select',
                appConfigData: 'processingPlaybackSource',
                label: 'Playback Source',
                defaultVal: 'debut',
                featureFlag: 'VPPIntegration',
                disabled: true
            }
        ],
        requiredFields: ['language']
    },
    audio: {
        details: [
            {
                id: 'Channels'
            },
            {
                id: 'language'
            }
        ],
        fields: [
            {
                id: 'jobType',
                label: 'Job',
                type: 'select',
                appConfigData: 'genieJobTypes',
                validation: {
                    type: 'atmos',
                    configId: 'fileFormat'
                },
                filterSelectOptions: [
                    {
                        configId: 'type',
                        values: ['audio'],
                        optionsToRemove: ['proxisUnprotected', 'proxisShortform']
                    }
                ]
            },
            {
                id: 'fileFormat',
                label: 'Format',
                type: 'select',
                appConfigData: 'audioFormats',
                validation: {
                    type: 'atmos',
                    configId: 'fileFormat'
                },
                errorMessage: 'Processing of Atmos audio for shortform content is not supported at this time.  Please update the selected Job',
                filterSelectOptions: [
                    {
                        configId: 'playbackSource',
                        values: ['VPP'],
                        optionsToRemove: ['atmos']
                    }
                ]
            },
            {
                id: 'language',
                label: 'Language',
                type: 'select',
                appConfigData: 'rfc5646LanguageTags',
                defaultVal: 'en'
            },
            {
                id: 'playbackSource',
                type: 'select',
                appConfigData: 'processingPlaybackSource',
                label: 'Playback Source',
                defaultVal: 'debut',
                featureFlag: 'VPPIntegration',
                disabled: true
            }
        ],
        processingFields: [
            {
                type: 'reprocess'
            },
            {
                id: 'multiRegion',
                label: 'International Review',
                type: 'switch',
                defaultVal: true
            }
        ],
        advancedFields: [
            {
                id: 'uploadTimeStamp',
                label: 'CDN Timestamp',
                type: 'textWithButton',
                buttonFunction: () => Date.now(),
                buttonCopy: 'New Timestamp',
                validation: {
                    type: 'number',
                    digits: 13
                },
                formatting: 'number',
                errorMessage: 'Upload Timestamp must be an EPOCH timestamp'
            },
            {
                id: 'startTimecode',
                type: 'text',
                validation: { type: 'time' },
                errorMessage: 'Invalid time',
                renderField: [
                    {
                        configId: 'fileFormat',
                        values: ['atmos']
                    }
                ]
            },
            {
                id: 'endOption',
                type: 'select',
                appConfigData: 'timeCodes',
                conditionalFields: {
                    custom: [{
                        id: 'endCustom',
                        type: 'text',
                        validation: { type: 'time' },
                        errorMessage: 'Invalid time'
                    }]
                },
                renderField: [
                    {
                        configId: 'fileFormat',
                        values: ['atmos']
                    }
                ]
            },
            {
                id: 'offset',
                type: 'text',
                formatting: 'number',
                validation: {
                    type: 'time',
                    allowZero: true
                },
                errorMessage: 'Invalid time',
                renderField: [
                    {
                        configId: 'fileFormat',
                        values: ['atmos']
                    }
                ]

            },
            {
                id: 'bitrate',
                type: 'text',
                validation: {
                    type: 'number',
                    digits: 3
                },
                formatting: 'number',
                errorMessage: 'Invalid number, must be 3 digits.',
                renderField: [
                    {
                        configId: 'fileFormat',
                        values: ['atmos']
                    }
                ]
            },
            {
                id: 'sourceFormat',
                type: 'select',
                appConfigData: 'atmosFormats',
                renderField: [
                    {
                        configId: 'fileFormat',
                        values: ['atmos']
                    }
                ]
            }
        ],
        requiredFields: ['jobType', 'fileFormat', 'language']
    },
    subtitle: {
        details: [
            {
                id: 'language'
            },
            {
                id: 'framerate',
                format: 'framerate'
            },
            {
                id: 'timestamp',
                format: 'time'
            }
        ],
        fields: [
            {
                id: 'jobType',
                label: 'Job',
                type: 'select',
                appConfigData: 'genieJobTypes',
                filterSelectOptions: [
                    {
                        configId: 'type',
                        values: ['subtitle'],
                        optionsToRemove: ['proxisUnprotected', 'proxisShortform']
                    }
                ]
            },
            {
                id: 'fileFormat',
                label: 'Format',
                type: 'select',
                appConfigData: 'subtitleFormats'
            },
            {
                id: 'language',
                label: 'Language',
                type: 'select',
                appConfigData: 'rfc5646LanguageTags'
            },
            {
                id: 'playbackSource',
                type: 'select',
                appConfigData: 'processingPlaybackSource',
                label: 'Playback Source',
                defaultVal: 'debut',
                featureFlag: 'VPPIntegration',
                disabled: true
            }
        ],
        processingFields: [
            {
                type: 'reprocess'
            },
            {
                id: 'multiRegion',
                label: 'International Review',
                type: 'switch',
                defaultVal: true
            }
        ],
        advancedFields: [
            {
                id: 'uploadTimeStamp',
                label: 'CDN Timestamp',
                type: 'textWithButton',
                buttonFunction: () => Date.now(),
                buttonCopy: 'New Timestamp',
                validation: {
                    type: 'number',
                    digits: 13
                },
                formatting: 'number',
                errorMessage: 'Upload Timestamp must be a number'
            }
        ],
        requiredFields: ['jobType', 'fileFormat', 'language']
    },
    image: {
        details: [
            {
                label: 'Image Preview',
                id: 'imagePreview',
                format: 'imagePreview'
            },
            {
                id: 'resolution'
            }
        ],
        fields: [
            {
                id: 'imageType',
                label: 'Image Type',
                type: 'select',
                appConfigData: 'imageTypes',
                relatedValues: {
                    title_treatment: [
                        {
                            configId: 'serveAs',
                            value: 'png'
                        }
                    ]
                }
            },
            {
                id: 'serveAs',
                label: 'Serve As',
                type: 'select',
                appConfigData: 'imageServeAsFormats'
            },
            {
                id: 'playbackSource',
                type: 'select',
                appConfigData: 'processingPlaybackSource',
                label: 'Playback Source',
                defaultVal: 'debut',
                featureFlag: 'VPPIntegration',
                disabled: true
            }
        ],
        processingFields: [
            {
                type: 'reprocess'
            }
        ],
        requiredFields: ['imageType', 'serveAs']
    }
};

// fileProcessingConfig structure
// [{
//     [type of file] {
//         details: [File details that should be rendered],
//         fields: [field objects]
//         processingFields: [
//             //current 2 options
//             {
//                 type: 'reprocess'
//             },
//             {
//                 id: 'multiRegion',
//                 label: 'International Review',
//                 type: 'switch',
//                 defaultVal: true
//             }
//         ]
//         advancedFields: [field objects to be collapsed on initial render]
//         requiredFields: [jobConfig ids]
//     }
// }]

// Fields object structure
// {
//     id: jobConfig id
//     label: Form field label -- optional if label and id are the same
//     appConfigData: appConfig id to get select options
//     validation: {
//         type:
//         Optional validation
//         configId: if validation should look at an adiaitonal jobConfig value
//         allowZero: if time should allow zero
//         digits: how many digits a number should be
//     }
//     errorMessage:
//     defaultVal:
//     type: type of field that should be rendered (text, select, switch ect.)
//     formatting: how the value should be formatted for the jobConfig
//     conditionalFields: {
//         [value option of current field]: [field object(s)]
//     },
//     filterSelectOptions: [
//         {
//             configId: other jobConfig value to check
//             values: [values of configId that should change current field options]
//             optionsToRemove: [options of current field to remove]
//         }
//     ],
//     relatedValues: {
//         [value of current field]: [
//             {
//                 configId: other jobConfig value to check
//                 value: value of configId that should be set
//             }
//         ]
//     }
// }
